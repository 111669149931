import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/kontrak'

export default {
  get (idKontrak, params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/${idKontrak}/angsuran/${queryParams}`)
  },

  create (idKontrak, params) {
    return httpClient.post(`${endpoint}/${idKontrak}/angsuran`, params)
  },

  update (idKontrak, params) {
    return httpClient.patch(`${endpoint}/${idKontrak}/angsuran`, params)
  },

  delete (idKontrak, idAngsuran) {
    return httpClient.delete(`${endpoint}/${idKontrak}/angsuran/${idAngsuran}`)
  }
}
